@tailwind base;
@tailwind components;
@tailwind utilities;

*
{
    font-family: "Poppins", sans-serif;
    /* letter-spacing: 2px; */
}
.active
{
  background-color: blue;
  color: white;
}
@media (min-width: 768px) {
  .active
  {
    background-color: transparent;
    color: blue;
  }
}
.charity-sec
{
    background-image: url('../images/charity-sec.jpeg')  no-repeat center;
    background-size: cover;
}

/* gallery */
.dark-overlay {
    position: relative; /* Positioning context for overlay */
  }
  
  .dark-overlay::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Position overlay relative to its parent */
    top: 0; /* Align overlay to top of parent */
    left: 0; /* Align overlay to left of parent */
    width: 100%; /* Cover entire parent */
    height: 100%; /* Cover entire parent */
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay color with opacity */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .dark-overlay:hover::before {
    background-color: rgba(0, 0, 0, 0); /* Remove overlay on hover */
  }
  
.dark-overlay {
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
.dark-overlay:hover {
    filter: brightness(100%); /* Remove brightness filter on hover */
  }
  
/* gallery end */

.welcome
{
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url("../images/charity-bg.jpg") no-repeat center;
}
nav .logo
{
    width: 100%;
    display: flex;
    justify-content: center;
}
.welcome-txt h2
{
    color: white;
    text-align: center;
    margin-top: 100px;
    font-size: 25px;
    line-height: 50px;
}
#google_translate_element {
  display: inline-block !important;
}
/* Adjust the font size of the translation widget */
.goog-te-combo {
  font-size: 14px !important;
}